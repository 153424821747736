<template>
  <div class="wrap">
    <header class="header">
      <img class="header-title" src="/images/news/title.png" alt="" />
    </header>
    <div style="display: flex;flex-direction: column;align-items: center">
      <div class="top-out-box">
      <div class="content">
        <h3 class="content-title">小镇动态</h3>
        <div class="top-box">
          <div class="top-left-box">
            <BannerSwiper :data="hotData" @swiperChange="swiperChange" :list="hotData" v-if="hotData.length>0"/>
          </div>
          <div class="top-right-box" v-if="hotData.length>0">
            <h3 class="top-right-title">
              {{hotData[hotIndex].title}}
            </h3>
            <p class="top-right-desc">
              {{getText(hotData[hotIndex].content)}}
            </p>
            <p class="top-right-date">
              {{ $date.moment(hotData[hotIndex].createDate,'YYYY-MM-DD HH:mm') }}
              <span style="margin-left: 0.5rem">
                 作者:{{hotData[hotIndex].townName}}
              </span>
            </p>

          </div>
        </div>
      </div>
      </div>
      <div class="tab-out-box">
        <div class="tab-out-white-box"/>

        <div class="tab-box">
        <a-tabs :tabPosition="left">
          <a-tab-pane key="1" tab="小镇动态" >
            <List type="0" :townId="this.$route.query?.tid"/>
          </a-tab-pane>
          <a-tab-pane key="2" tab="政策文件">
            <List type="1" :townId="this.$route.query?.tid"/>
          </a-tab-pane>
          <a-tab-pane key="3" tab='招商信息'>
            <List type="2" :townId="this.$route.query?.tid"/>
          </a-tab-pane>
        </a-tabs>
        </div>
      </div>

      </div>

  </div>
</template>
<script>

import { useRoute, onBeforeRouteUpdate } from 'vue-router'
import { useList, useParams } from '@/hooks/useList'
import api from '@/api'
import { hideTextTag, formatDate } from '@/utils/tools'
import BannerSwiper from '@/components/news/banner-swiper'
import List from '@/components/news/list'
import {ref} from 'vue'
export default {
  name: 'news',
  components: {
    BannerSwiper,
    List
  },
  setup () {
    const route = useRoute()
    const hotData = ref([])
    const hotIndex = ref(0)
    const filter = list => {
      return list.map(item => ({
        ...item,
        content: hideTextTag(item.content),
        publishDate: formatDate(item.publishDate)
      }))
    }
    const { params, search, } = useParams({
      townId: route.query?.tid ?? null,
      pageNum: route.query?.pn ?? 1,
      pageSize: 5
    })

    const getData = async () => {
       const getHot = async () =>{
         let res =  await api.hotspot({townId:route.query?.tid ?? null,})
         hotData.value = res || []

       }
       getHot()
    }
    const getText = (str) =>{
      let words = str.replace(/<[^<>]+>/g,'').replace(/&nbsp;/,'')
      return words
    }
    const swiperChange = (index) =>{
      hotIndex.value = index
    }

    getData()
      return {
      params,
      search,
      hotData,
      hotIndex,
      getText,
      swiperChange
    }

  },
  methods: {
    goPage (url) {
      this.$router.push(url)
    },
    pageChange (page) {
      this.$router.push({
        query: {
          pn: page
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  min-height: calc(100vh - 1.06rem);
  //background: url("/images/common/bg.png");
  background: #F4F4F4;
  .header {
    height: 7.36rem;
    width: 100%;
    background: url("/images/news/banner.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    .header-title {
      margin-top: (7.36rem-1.6rem)/2;
      width: 6.4rem;
      height: 1.6rem;
    }
  }
  .top-box {
    display: flex;
      .top-left-box {
        width: 5rem;
        height: 3.15rem;

      }
    .top-right-box {
      display: flex;
      flex-direction: column;
      margin-left: 0.26rem;
      flex: 1;

      .top-right-title {
        color: #000000;
        font-size: 0.22rem;
      }
      .top-right-desc {
        margin-top: 0.15rem;
        color: #666666;
        font-size: 0.16rem;
        @include text-moreLine(8)
      }
      .top-right-date {
        color: #999999;
        font-size: 0.14rem;
        margin-top: 0.2rem;
      }

    }
  }
  .tab-out-box {

    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    .tab-out-white-box {
      width: 100%;
      background: #ffffff;
      height: 0.65rem;
      position: absolute;
    }
  }


  .top-out-box{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    padding-bottom: 0.4rem;
  }

  .content {
    width: 12rem;
    padding: 0.6rem 0 0;

  }
  .tab-box {
    width: 12rem;
  }

    /*去掉底部边框*/
  /deep/ .ant-tabs-nav-wrap {
    margin-bottom: 0;
    border-bottom: 1px solid transparent;
  }

  /deep/ .ant-tabs-tab-active {
    color: #01398D;
  }

  /deep/ .ant-tabs-tab {
    height: 0.65rem;
    &:hover {
      color: #01398D;
    }
  }
  /deep/ .ant-tabs-ink-bar {
    background: #01398D;
  }
  /deep/ .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #01398D;
  }
}
</style>
