<template>
  <div class="box" v-show="data.length>0">
    <swiper
        :spaceBetween="5"
        :loop="true"
        :autoplay="{
          delay: 3000,
        }"

        @swiper="setSwiper"
        @slideChange="swiperChange"
        :navigation="{
          prevEl: '.previous',
          nextEl: '.next',
        }"
    >
      <swiper-slide v-for="(item, index) in data" :key="index"
        @click="goPage(item)"
      >
        <div class="control-item">
          <img :src="item.picture" class="control-img">
<!--          <img src="/images/activity/banner.png" class="control-img"   v-if="item.type === 'pic'">-->
<!--          <video-->
<!--              class="item-video"-->
<!--              v-else-->
<!--              :src="item.url"-->
<!--              muted-->
<!--              autoplay-->
<!--              loop-->
<!--          />-->

        </div>

      </swiper-slide>

    </swiper>
    <div class="cover-box">
        <p class="cover-text">
          <span class="cover-first-text">{{currentIndex+1}}</span>/{{data.length}}
        </p>
      <div >
        <span  class="previous">
          <img src="/images/news/swiper-left.png" class="arrow-img">
        </span>
        <span class="next">
          <img src="/images/news/swiper-right.png" class="arrow-img">
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/swiper.scss'
import {ref} from 'vue'
import SwiperCore, {Controller, Navigation} from 'swiper'
SwiperCore.use([Controller, Navigation])
export default {
  name: 'bannerswiper',
  components: {
    Swiper,
    SwiperSlide,
  },
  props:{
    data:{
      type: Array,
      default:()=>[]
    },


  },
  methods:{
    goPage(d){
      this.$router.push(`/articledetail?pt=1&id=${d.id}${this.$route.query.tn?`&tn=${this.$route.query.tn}`:''}${this.$route.query.tid?`&tid=${this.$route.query.tid}`:''}`)
    }
  },
  emits:['swiperChange'],
  setup(props,ctx) {
    let swiper = null;
    let data = props.data || [];

    let currentIndex = ref(0);

    const getTrueIndex = (m) =>{
      if (m<=0){
        m = data.length;
      }
      if (m>=data.length){
        m = 0;
      }
      return m;
    }

    const setSwiper = (s) => {
      swiper = s;
    }

    const onSwiper = (swiper) => {
      // console.log(swiper);
    };
    const onSlideChange = (direction) => {
      console.log('------',swiper.realIndex,swiper.activeIndex,getTrueIndex(swiper.realIndex+1),data.length,)
      if (direction == 'right'){

        swiper.slideTo(getTrueIndex(swiper.activeIndex+1),500,false);
      }else {
        swiper.slideTo(getTrueIndex(swiper.activeIndex-1),500,false);
      }
    };



    const swiperChange = (swiper) => {
      let m =  swiper.realIndex;
      ctx.emit('swiperChange',m);
      currentIndex.value = m ;
    }

    return {
      currentIndex,
      setSwiper,
      onSwiper,
      onSlideChange,
      swiperChange,

    };
  },
}
</script>

<style lang="scss" scoped>

  .box {
    width: 5rem;
    display: flex;
    position: relative;
    height: 3.15rem;
  }

  .control-item {

    height: 3.15rem;

    width: 5rem;
    cursor: pointer;
  }
  .control-img,
    .item-video
  {
    width: 5rem;
    height: 3.15rem;
    object-fit: cover;
    display: block;
  }
  .cover-box {
    background: rgba(0,0,0,0.5);
    height: 0.41rem;
    position: absolute;
    width: 5rem;
    bottom: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    padding: 0 0.2rem;
    align-items: center;

    .cover-text {
      color:#999999;
      font-size: 0.18rem;
    }

    .cover-first-text {
      color: #E15C23;
    }

    .arrow-img {
      width: 0.13rem + 0.2rem;
      height: 0.13rem + 0.2rem;
      cursor: pointer;
      padding: 0.1rem;
    }
  }


</style>
