<template>
  <ul class="content-list">
    <li
        v-for="item in list"
        :key="item.id"
        class="list-item"
        @click="goPage(`/articledetail?pt=1&id=${item.id}${this.$route.query?.hasOwnProperty('tn')?`&tn=${this.$route.query?.tn}`:''}${this.$route.query?.hasOwnProperty('tid')?`&tid=${this.$route.query?.tid}`:''}`)"
    >
      <img class="left-cover" :src="item.picture" alt="" />
      <div class="item-content">
        <h3 class="content-title">
          {{ item.title }}
        </h3>
        <p class="content-desc">
          {{hideText(item.content)  }}
        </p>
        <h4 class="content-copyright">

          <span class="copyright-date">{{$date.moment(item.createDate)}}</span>
          作者：{{ item.townName }}
        </h4>
      </div>
    </li>
  </ul>
  <NoData :visible="!list.length" />
  <div class="page">
    <Page
        class="page-tools"
        :size="params.pageSize"
        :current="pageNum"
        :total="total"
        @pageChange="changePageNum"
    />
  </div>
</template>

<script>
import NoData from '@/components/common/nodata'
import Page from '@/components/common/paginationwhite'
import {onBeforeRouteUpdate, useRoute} from 'vue-router'
import {formatDate, hideTextTag} from '@/utils/tools'
import {useList, useParams} from '@/hooks/useList'
import api from '@/api'
import {reactive, ref} from 'vue'

export default {
  name: 'list',
  components: {
    Page,
    NoData,
  },
  props:{
    type: {
      type:Number,
      default:0
    },
    townId:{
      type:String,
      default: null
    }
  },
  setup (props) {
    const route = useRoute()
    const filter = list => {
      return list.map(item => ({
        ...item,
        content: hideTextTag(item.content),
        publishDate: formatDate(item.publishDate)
      }))
    }

    const typenums = {0:'TOWN_DYNAMIC',1:'POLICY_DOCUMENT',2:'INVESTMENT_INFORMATION'}

    const total = ref(0)
    const pageNum = ref(1)
    const list = ref([])
    const params = reactive({
      pageNum:  pageNum.value,
      pageSize: 5,
      tabs:'TREND',
      townId:props.townId,
      townDynamicTypeEnum:typenums[props.type??0]})

    const getData = async () => {
        params.pageNum = pageNum.value;

      let res = await api.townProduct(params)

      total.value = res.total ?? 0;
      list.value = res.records ?? [];
    }

    const changePageNum = (p) =>{
      pageNum.value = p
      getData()
    }
    const hideText = (p) =>{
      return hideTextTag(p)
    }

    getData()
    return {
      total,
      pageNum,
      params,
      list,
      changePageNum,
      hideText
    }

  },
  methods: {
    goPage (url) {
      this.$router.push(url)
    },
    pageChange (page) {
      this.$router.push({
        query: {
          pn: page,
          type:'0'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-box {
  width: 12rem;

  .content-title {
    font-size: 0.3rem;
    font-weight: bold;
    color: #333333;
  }
  .content-subTitle {
    font-size: 0.14rem;
    font-weight: normal;
    color: #999999;
  }
  .content-list {
    position: relative;
    margin-top: 0.35rem;
    .list-item {
      display: flex;
      margin-bottom: 0.28rem;
      &:hover {
        cursor: pointer;
        .item-content {
          transition: all 200ms linear;
          background: #b82b2b;
          box-shadow: 0 0.08rem 0.06rem rgba(184, 43, 43, 0.2);
          .content-title {
            color: #fff;
          }
          .content-desc {
            color: #eba2a2;
          }
          .content-copyright {
            color: #eba2a2;
          }
        }
      }
      .left-cover {
        display: block;
        flex-shrink: 0;
        width: 2.85rem;
        height: 1.85rem;
        object-fit: cover;
      }
      .item-content {
        position: relative;
        width: 100%;
        overflow: hidden;
        height: 1.85rem;
        padding: 0.12rem 0.4rem;
        box-sizing: border-box;
        background: #fff;
        transition: all 200ms linear;
        .content-title {
          font-size: 0.18rem;
          font-weight: 400;
          color: #333333;
          @extend .text-oneLine;
        }
        .content-desc {
          margin-top: 0.2rem;
          font-size: 0.16rem;
          color: #999999;
          line-height: 0.27rem;
          @include text-moreLine(2);
        }
        .content-copyright {
          position: absolute;
          left: 0.4rem;
          bottom: 0.12rem;
          font-size: 0.14rem;
          color: #999999;
          .copyright-date {
            margin-right: 0.2rem;
          }
        }
      }
    }
  }
}
.page {
  display: flex;
  justify-content: center;
  margin: 0.8rem 0;
}
</style>
